@font-face {
  font-family: 'WorkSans Italic';
  src: url('./assets/fonts/WorkSans-Italic-VariableFont_wght.ttf')
    format('truetype');
}

@font-face {
  font-family: 'WorkSans Italic';
  src: url('./assets/fonts/WorkSans-Italic-VariableFont_wght.eot');
}

@font-face {
  font-family: 'WorkSans Italic';
  src: url('./assets/fonts/WorkSans-Italic-VariableFont_wght.woff')
    format('woff');
}

@font-face {
  font-family: 'WorkSans Italic';
  src: url('./assets/fonts/WorkSans-Italic-VariableFont_wght.otf')
    format('truetype');
}

@font-face {
  font-family: 'WorkSans';
  src: url('./assets/fonts/WorkSans-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'WorkSans';
  src: url('./assets/fonts/WorkSans-VariableFont_wght.eot');
}

@font-face {
  font-family: 'WorkSans';
  src: url('./assets/fonts/WorkSans-VariableFont_wght.woff') format('woff');
}

@font-face {
  font-family: 'WorkSans';
  src: url('./assets/fonts/WorkSans-VariableFont_wght.otf') format('truetype');
}
