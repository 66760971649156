@mixin horizontal-mobile {
  @media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 926px) {
    @content;
  }
}

@mixin slide-biggest {
  @media only screen and (max-width: 1600px) {
    @content;
  }
}

@mixin slide-big {
  @media only screen and (max-width: 1500px) {
    @content;
  }
}

@mixin slide-rem {
  @media only screen and (max-width: 1400px) {
    @content;
  }
}

@mixin slide-medium {
  @media only screen and (max-width: 1300px) {
    @content;
  }
}

@mixin slide-small {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin xxxl {
  @media only screen and (max-width: 1600px) {
    @content;
  }
}

@mixin biggest-screen {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}

@mixin custom {
  @media (min-width: 1530px) and (max-width: 1600px) {
    @content;
  }
}

@mixin media-wrapper {
  @media only screen and (max-width: 1536px) {
    @content;
  }
}

@mixin xlx {
  @media (min-width: 1280px) and (max-width: 1366px) {
    @content;
  }
}

@mixin big-screen {
  @media screen and (min-width: 1367px) {
    @content;
  }
}

@mixin main-screen {
  @media only screen and (max-width: 1367px) {
    @content;
  }
}

@mixin xxl {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin lg-1 {
  @media only screen and (max-width: 875px) {
    @content;
  }
}

@mixin ex-medium {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin ex-small {
  @media only screen and (max-width: 576px) {
    @content;
  }
}

@mixin ex-smaller {
  @media only screen and (max-width: 429px) {
    @content;
  }
}

@mixin ex-smallest {
  @media only screen and (max-width: 400px) {
    @content;
  }
}

@mixin objectCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin objectContain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@mixin centerAbsolute {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

@mixin flexAlignCenter {
  display: flex;
  align-items: center;
}

@mixin flexJustifyCenter {
  display: flex;
  justify-content: center;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin fontSize($value) {
  font-size: ($value / 16) + rem;
}

$baseWidth: 1920;
$baseHeight: 1080;

$baseWidth1: 1366;
$baseHeight1: 768;

:root {
  --screenWidth: 1920;
  --screenHeight: 1080;

  --big-title-font-size: 64px;
  --big-title-line-height: 64px;

  --title-font-size1: 40px;
  --title-line-height1: 40px;

  --title-font-size2: 36px;
  --title-line-height2: 36px;

  --title-font-size3: 32px;
  --title-line-height3: 32px;

  --title-font-size4: 23.2px;
  --title-line-height4: 23.2px;

  --zoom: 1;

  --hightlight-color: #f1c959;
  --hightlight-color1: #e0a942;
}

@media (max-width: 1584px) {
  :root {
    --screenWidth: 1584;
    --screenHeight: 1112;

    --zoom: 1;
  }
}

@media (max-width: 1536px) {
  :root {
    --screenWidth: 1536;
    --screenHeight: 864;

    --zoom: 1;
  }
}

@media (max-width: 1440px) {
  :root {
    --screenWidth: 1440;
    --screenHeight: 900;

    --zoom: 1;
  }
}

@media (max-width: 1366px) {
  :root {
    --screenWidth: 1366;
    --screenHeight: 768;

    --zoom: 1;
  }
}

@media (max-width: 1280px) {
  :root {
    --screenWidth: 1280;
    --screenHeight: 720;
  }
}

@media (max-width: 428px) {
  :root {
    --screenWidth: 428;
    --screenHeight: 926;
  }
}

@media (max-width: 393px) {
  :root {
    --screenWidth: 393;
    --screenHeight: 720;
  }
}

@function calWidth($with) {
  @return calc((#{$with} * var(--zoom)));
}

@function calHeight($height) {
  @return calc((#{$height} * var(--zoom)));
}

@function calPercentage($value, $percent) {
  @return calc((#{$value} * var(--screenHeight)) / #{$baseHeight1});
}

$font: 'WorkSans';
@mixin clear {
  *zoom: 1;
  &:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden;
  }
}

$blue: #2c7ffc;
