@import '../../assets/scss/mixin';

.homepage {
    width: 100%;
    margin: 0 auto;

    .container {
        height: 100%;
    }
}

.container {
    max-width: 1366px;
    width: 100%;
    margin: 0 auto;
    @include clear;
    position: relative;

    @include biggest-screen {
        max-width: 1440px;
    }

    @include main-screen {
        max-width: 1276px;
    }

    @include media-wrapper {
        max-width: 1280px;
    }
}

.header {
    height: 100vh;
    position: relative;
    z-index: 2;
    overflow: hidden;

    &__top {
        display: flex;
        justify-content: space-between;
        @include ex-medium {
            padding: 0 10px;
            flex-wrap: wrap;
        }
    }

    &__bg {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        &::before {
            content: '';
            width: 50%;
            height: 100%;
            background: linear-gradient(to right, #000, transparent);
            position: absolute;
            left: 0;
            top: 0;
        }

        &::after {
            content: '';
            width: 50%;
            height: 100%;
            background: linear-gradient(to left, #000, transparent);
            position: absolute;
            right: 0;
            top: 0;
        }

        &__img01 {
            max-width: 100%;
            height: 100%;

            @include ex-medium {
                max-width: none;
                width: 100%;
                object-fit: cover;
            }
        }

        &__bg01 {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -2;
        }

        &__img02 {
            max-width: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }

        &__bottom {
            background: linear-gradient(to top, #000, transparent);
            width: 100%;
            height: 265px;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    &__logo {
        position: relative;
        z-index: 2;
        padding: 45px;
        padding-left: 0;
        padding-right: 0;
        // float: left;
        @include big-screen {
            padding-left: 45px;
        }

        img {
            max-width: 100%;

            @include ex-smaller {
                width: 110px;
            }
        }
    }

    &__right {
        position: relative;
        z-index: 2;
        // float: right;
        // padding-top: 50px;
        display: flex;
        align-items: center;
        @include big-screen {
            padding-right: 45px;
        }

        @include ex-smallest {
            padding-top: 0;
            width: 100%;
            justify-content: flex-end;
            top: -20px;
        }
    }

    &__btn {
        background: $blue;
        border: 1px solid #ffffff;
        border-radius: 5px;
        font-weight: 500;
        @include fontSize(16);
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #ffffff;
        display: block;
        width: 119px;
        text-decoration: none;
        padding: 5px 0;
    }

    &__nav {
        > li {
            display: inline-block;
            position: relative;

            &:last-child {
                > p {
                    background-image: url('../../assets/imgs/icon/arrow-down.png');
                    background-position: right 2px center;
                    background-size: 8px 6px;
                    background-repeat: no-repeat;
                    padding-right: 18px;
                    margin-left: 20px;
                    cursor: pointer;
                }
            }

            > a {
                display: inline-block;
                margin-left: 20px;
                @include ex-medium {
                    margin-left: 10px;
                }

                &:hover {
                    opacity: .8;
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }

    &__dropdown {
        position: absolute;
        background: linear-gradient(
                130.43deg,
                rgba(45, 129, 255, 0.5) 29.91%,
                rgba(73, 243, 255, 0.5) 132.2%
        );
        backdrop-filter: blur(40px);
        border-radius: 5px;
        width: 143px;
        right: 0;
        top: 30px;
        padding: 8px 8px 0;

        li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.8);

            &:last-child {
                border-bottom: 0;
            }

            p {
                font-weight: 500;
                @include fontSize(12);
                line-height: 19px;
                letter-spacing: -0.02em;
                color: #ffffff;
                display: block;
                padding: 8px 10px;
                text-decoration: none;
                cursor: pointer;

                img {
                    margin-right: 5px;
                    vertical-align: middle;
                    border-radius: 50%;
                }
            }
        }
    }

    &__list {
        position: absolute;
        z-index: 2;
        top: 35%;
        left: 45px;
        list-style-type: none;
        @include main-screen {
            left: 0;
        }

        @include ex-medium {
            top: 50%;
            left: 10%;
        }

        @include ex-smallest {
            top: 55%;
        }

        li {
            a {
                cursor: pointer;
                font-weight: 500;
                @include fontSize(15);
                line-height: 23px;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: rgba(255, 255, 255, 0.8);
                display: block;
                text-decoration: none;
                margin-bottom: 17px;
                transition: 0.3s all;
                position: relative;
                @include ex-medium {
                    margin-bottom: 7px;
                }

                &:hover {
                    color: #fff;
                    text-shadow: 3px 1px 7px #fff;
                    padding-left: 20px;

                    &::before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        background-image: url('../../assets/imgs/icon/icon_01.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    &__btn01 {
        font-weight: 500;
        @include fontSize(16);
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #ffffff;
        position: absolute;
        left: 50%;
        bottom: 95px;
        transform: translateX(-50%);
        display: block;
        text-decoration: none;
        width: 220px;
        cursor: pointer;
        background: linear-gradient(
                94.36deg,
                rgba(49, 148, 253, 0.49) 0.87%,
                rgba(73, 243, 255, 0.49) 97.84%
        );
        border-radius: 40px;
        padding: 5px 0;
        padding-right: 30px;
        z-index: 2;
        @include ex-medium {
            bottom: 40px;
        }

        &:hover {
            background: linear-gradient(
                    94.36deg,
                    rgba(49, 148, 253, 0.7) 0.87%,
                    rgba(73, 243, 255, 0.7) 97.84%
            );
        }

        &::after {
            content: '';
            width: 26px;
            height: 26px;
            background-image: url('../../assets/imgs/icon/arrow_right.png');
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        @include ex-medium {
            bottom: 50px;
        }
    }

    &__gr {
        position: absolute;
        right: 45px;
        top: 280px;

        @include main-screen {
            right: 0;
        }

        @include ex-medium {
            left: 10%;
            top: 200px;
        }

        @include ex-smallest {
            top: 200px;
        }
    }

    &__tt {
        font-weight: bold;
        @include fontSize(48);
        line-height: 56px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #ffffff;
        position: relative;
        z-index: 2;
        text-shadow: 2px 0 0 #2d81ff, -2px 0 0 #2d81ff, 0 2px 0 #2d81ff,
        0 -2px 0 #2d81ff, 1px 1px #2d81ff, -1px -1px 0 #2d81ff, 1px -1px 0 #2d81ff,
        -1px 1px 0 #2d81ff;
        margin-bottom: 10px;
        max-width: calWidth(518px);
        max-width: 501px;
        @include main-screen {
            max-width: 501px;
        }

        @include ex-medium {
            @include fontSize(40);
        }

        @include ex-smaller {
            @include fontSize(30);
        }
    }

    &__text {
        position: relative;
        z-index: 2;
        font-weight: 500;
        @include fontSize(16);
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #ffffff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: table;
        max-width: calWidth(320px);
        max-width: 320px;
        margin-left: 70px;
        @include ex-medium {
            @include fontSize(14);
        }

        &::before {
            content: '';
            width: 59px;
            height: 2px;
            background-color: #fff;
            position: absolute;
            left: -70px;
            top: 25px;
        }
    }
}

.homepage_content {
    background-image: url('../../assets/imgs/home/bg_main.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000;
    padding-bottom: 65px;
}

.summary {
    padding-top: 60px;
    position: relative;
    padding-bottom: 25px;
    margin-top: -1px;

    &::before {
        content: '';
        width: 100%;
        height: 308px;
        background: linear-gradient(
                179.98deg,
                #000000 3.98%,
                rgba(0, 0, 0, 0) 99.99%
        );
        transform: matrix(-1, 0, 0, 1, 0, 0);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    &__container {
        max-width: calWidth(953px);
        margin: 0 auto;
        width: 100%;
        @include main-screen {
            max-width: 953px;
        }

        @include ex-medium {
            max-width: 96%;
        }
    }

    &__box {
        max-width: 96%;
        width: calWidth(993px);
        margin: 0 auto;
        display: flex;
        margin-bottom: 40px;
        position: relative;
        padding-left: 30px;
        @include main-screen {
            width: 993px;
        }

        &__left {
            background: linear-gradient(
                    109.07deg,
                    rgba(45, 129, 255, 0.325) 55.69%,
                    rgba(93, 244, 255, 0.285) 94.73%
            );
            padding: 50px 80px;
            width: calWidth(567px);
            @include main-screen {
                width: 567px;
            }
        }

        &__right {
            width: calWidth(386px);
            max-width: 100%;
            @include main-screen {
                width: 386px;
                // height: 218px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__nav {
        width: calWidth(953px);
        margin: 0 auto;
        @include main-screen {
            width: 953px;
        }

        @include ex-medium {
            max-width: 96%;
        }

        > li {
            display: flex;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            margin-bottom: 40px;
            position: relative;

            @include ex-medium {
                flex-direction: column;
            }

            &::before {
                content: '';
                width: 8px;
                height: 8px;
                background-image: url('../../assets/imgs/icon/icon_01.png');
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                top: -4px;
                left: 50%;
                transform: translateX(-50%);
            }

            &::after {
                content: '';
                width: 8px;
                height: 8px;
                background-image: url('../../assets/imgs/icon/icon_01.png');
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
            }

            transition: transform 400ms ease-in,
            padding-left 400ms ease-in,
            border-top-color 100ms ease-in-out,
            border-bottom-color 100ms ease-in-out;

            @media(hover: hover) and (pointer: fine) {
                &:hover {
                    border-color: transparent;

                    transform: translate(40px, 0);

                    &::before {
                        display: none;
                    }

                    &::after {
                        display: none;
                    }

                    .summary__nav__text {
                        width: auto;
                        flex: 1;
                        padding: 20px 80px;

                        background: linear-gradient(
                                109.07deg,
                                rgba(45, 129, 255, 0.325) 55.69%,
                                rgba(93, 244, 255, 0.285) 94.73%
                        );

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        &__text {
            width: 50%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px 0;

            &::before {
                content: '';
                width: 1px;
                height: 156px;
                background-color: #fff;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            @include ex-medium {
                width: 96%;

                &::before {
                    display: none;
                }
            }

            .title_01 {
                margin-bottom: 15px;
            }

            .list_01 {
                padding-left: 16px;
            }
        }

        &__img {
            width: calWidth(386px);
            max-width: 100%;
            text-align: right;
            margin-left: auto;
            @include main-screen {
                width: 386px;
            }

            img {
                max-width: 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.title-main {
    @include fontSize(36);
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #ffffff;
    position: relative;
    padding-bottom: 19px;
    text-align: center;
    margin-bottom: 40px;

    span {
        color: $blue;
    }

    &::before {
        content: '';
        width: 163px;
        height: 14px;
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translateX(-50%);
        background-image: url('../../assets/imgs/icon/icon_title_main.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.title_01 {
    font-weight: 600;
    @include fontSize(20);
    line-height: 23px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 30px;
}

.title_02 {
    font-weight: 600;
    @include fontSize(16);
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 5px;
}

.list_01 {
    padding-left: 20px;
    list-style: disc;

    li {
        font-weight: 500;
        @include fontSize(12);
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #ffffff;
    }
}

.mb10 {
    margin-bottom: 10px;
}

.ecosystem {
    margin-bottom: 130px;
    @include ex-medium {
        margin-bottom: 80px;
    }

    &__nav {
        display: flex;
        justify-content: center;

        @include ex-medium {
            flex-direction: column;
        }

        li {
            width: calWidth(305px);
            padding-top: 20px;
            border-top: 1px solid #fff;
            margin-right: 20px;

            transition: all 300ms ease-in;

            &:last-child {
                margin-right: 0;
            }

            @media(hover: hover) and (pointer: fine) {
                &:hover {
                    transform: translate(0, -20px);
                }
            }

            @include main-screen {
                width: 305px;
            }

            @include ex-medium {
                margin: 0 auto;

                &:last-child {
                    margin: 0 auto;
                }
            }
        }

        &__img {
            width: 100%;
            height: calHeight(244px);
            border: 1px solid #fff;
            border-top: 0;
            position: relative;

            span {
                position: absolute;
                bottom: -5px;
                left: 30%;
                width: 8px;
                height: 8px;
                background-image: url('../../assets/imgs/icon/icon_01.png');
                background-repeat: no-repeat;
                background-size: contain;
            }

            &::before {
                content: '';
                width: calWidth(62px);
                height: 1px;
                background-color: #fff;
                position: absolute;
                top: 0;
                left: 0;
            }

            &::after {
                content: '';
                width: calWidth(62px);
                height: 1px;
                background-color: #fff;
                position: absolute;
                top: 0;
                right: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__text {
            font-weight: 600;
            @include fontSize(20);
            line-height: 23px;
            text-align: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #ffffff;
            margin-top: 25px;
        }
    }

    &__video {
        width: calWidth(1279px);
        margin: 20px auto 0;
        border: 1px solid #fff;

        @include main-screen {
            width: 96%;
        }

        @include ex-medium {
            width: 92%;
        }

        img, video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.roadmap {
    margin-bottom: 170px;
    @include ex-medium {
        margin-bottom: 80px;
    }

    &__content {
        height: calHeight(506px);
        position: relative;
        margin-top: 55px;
        max-width: 1366px;
        width: 100%;
        margin: 0 auto;
        @include main-screen {
            height: 506px;

        }

        &::after {
            content: '';
            width: calc(100% - 170px);
            height: 2px;
            background-image: url('../../assets/imgs/home/Line-Roadmap.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__item {
        position: relative;
        height: 100%;
        width: 100%;

        &.hide {
            display: none;
        }

        &__block {
            width: calWidth(224px);
            width: 100%;
            height: calHeight(196px);
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;

            .wrap {
                width: 100%;
                position: relative;
                z-index: 3;
            }
        }

        &.down {
            .roadmap__item__block {
                bottom: 20px;
                padding-top: 10px;
            }

            .roadmap__item__dot::after {
                @include main-screen {
                    right: 8px;
                    top: 10px;
                }
            }
        }

        &.past {
            .roadmap__item__dot {
                width: 22px;
                height: 22px;
            }

            &.down .roadmap__item__dot::after {
                right: calWidth(9px);
                @include main-screen {
                    right: 10px;
                }
            }

            &.up .roadmap__item__dot::after {
                left: calWidth(8px);
                @include main-screen {
                    left: 10px;
                }
            }

            .roadmap__item__line {
                display: block;
            }
        }

        &.up {
            .roadmap__item__block {
                justify-content: flex-end;
                padding-bottom: 10px;
                top: 20px;

                &::before {
                    bottom: auto;
                    top: -20px;
                }
            }

            .roadmap__item__dot::after {
                background-image: url('../../assets/imgs/home/Vector-Up.png');
                top: auto;
                left: 8px;
                bottom: calHeight(12px);
                right: auto;
                width: calWidth(126px);
                height: calHeight(84px);
                @include main-screen {
                    width: 120px;
                    height: 81px;
                }
            }

            .roadmap__item__arrow {
                top: auto;
                bottom: calHeight(-14px);
                transform: translateX(-50%) rotate(180deg);
            }

            .roadmap__item__dot {
                //top: auto;
                //bottom: calHeight(-49px);
                @include main-screen {
                    //bottom: -49px;
                }
            }
        }

        &.current.up {
            .roadmap__item__dot {
                width: 25px;
                height: 25px;
            }
        }

        &.current.down {
            .roadmap__item__dot {
                width: 25px;
                height: 25px;
            }
        }

        &.current {
            .roadmap__item__dot {
                box-shadow: 0 0 10px 5px #c0ffe4;
                animation: orb 1.8s infinite;
            }

            .roadmap__item__line {
                display: block;
            }

            &.up .roadmap__item__dot::after {
                left: calWidth(7.5px);
                @include main-screen {
                    left: 12px;
                }
            }

            &.down .roadmap__item__dot::after {
                right: calWidth(9px);
                @include main-screen {
                    right: 11px;
                }
            }
        }

        &.active {
            .roadmap__item__block {
                border: 1px solid #ffffff;
                justify-content: center;
                background: linear-gradient(
                        180deg,
                        rgba(45, 129, 255, 0.17) 0%,
                        rgba(93, 244, 255, 0.085) 100%
                );

                &::before {
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: #fff;
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                }

                .roadmap__item__arrow {
                    display: block;
                }

                .roadmap__item__list {
                    display: block;
                }
            }

            .roadmap__item__dot::after {
                display: none;
            }
        }

        &__time {
            font-weight: 600;
            @include fontSize(20);
            line-height: 23px;
            text-align: center;
            letter-spacing: -0.02em;
            text-transform: capitalize;
            color: #ffffff;
            margin-bottom: 10px;
        }

        &__title {
            font-weight: 500;
            @include fontSize(16);
            line-height: 19px;
            text-align: center;
            letter-spacing: -0.02em;
            color: #ffffff;
        }

        &__list {
            margin: 0 auto;
            margin-top: 10px;
            display: none;

            li {
                font-weight: 500;
                @include fontSize(16);
                line-height: 19px;
                letter-spacing: -0.02em;
                color: #ffffff;
            }
        }

        &__arrow {
            width: 164px;
            width: calWidth(164px);
            height: 18px;
            height: calHeight(18px);
            background-image: url('../../assets/imgs/home/roadmap_icon_01.svg');
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            top: calHeight(-14px);
            left: 50%;
            transform: translateX(-50%);
            display: none;
        }

        &__dot {
            position: absolute;
            top: calHeight(-50px);
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            transform: translate(-50%, -50%);
            width: 18px;
            height: 18px;
            border-radius: 50%;
            z-index: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &::after {
                content: '';
                width: calWidth(172px);
                height: calHeight(136px);
                background-image: url('../../assets/imgs/home/Vector-down.png');
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                top: calHeight(11px);
                right: 9px;
                z-index: -1;
                @include main-screen {
                    width: 172px;
                    height: 136px;
                }
            }
        }

        &__line {
            width: calc(100% + 30px);
            height: 2px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-100%, -50%);
            z-index: -1;
            background: linear-gradient(
                    270deg,
                    #00f3ff 48.77%,
                    rgba(0, 243, 255, 0.8) 100%
            );
            display: none;
        }
    }

    .swiper {
        // padding-left: calWidth(120px);
        // padding-right: calWidth(120px);
        height: 100%;
        @include main-screen {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    .swiper-button-prev {
        background-image: url('../../assets/imgs/home/prev.svg');
        background-repeat: no-repeat;

        &::after {
            display: none;
        }
    }

    .swiper-button-next {
        background-image: url('../../assets/imgs/home/next.svg');
        background-repeat: no-repeat;

        &::after {
            display: none;
        }
    }

    .swiper-pagination {
        display: none;
    }

    .swiper-css-mode > .swiper-wrapper {
        overflow: inherit;
    }

    .swiper-button-prev {
        left: 70px;

        z-index: -1;
        opacity: .35;

        @include ex-medium {
            opacity: 1;
            z-index: 2;
        }
    }

    .swiper-button-next {
        right: 70px;

        z-index: -1;
        opacity: .35;

        @include ex-medium {
            opacity: 1;
            z-index: 2;
        }
    }

    .swiper-slide:first-child {
        .roadmap__item {
            .roadmap__item__line {
                display: block;
                width: 50%;
                background: linear-gradient(
                        270deg,
                        #00f3ff 48.77%,
                        rgba(0, 243, 255, 0) 100%
                );
            }
        }
    }
}

@keyframes orb {
    0%,
    100% {
        box-shadow: 0 0 5px 2px #8fffcf;
    }
    50% {
        box-shadow: 0 0 14px 2px #8fffcf;
    }
}

.team {
    margin-bottom: 120px;

    &__nav {
        display: flex;
        justify-content: center;
        list-style: none;
        margin-top: 50px;
        color: #ffffff;
        @include main-screen {
            width: 96%;
            margin: 0 auto;
        }
        @include ex-medium {
            flex-direction: column;
        }

        > li {
            width: calWidth(288px);
            position: relative;
            height: 407px;
            margin-right: 36px;

            &:last-child {
                margin-right: 0;
                @include ex-medium {
                    margin: 0 auto;
                }
            }

            &::before {
                content: '';
                width: 100%;
                height: 1px;
                background-color: #fff;
                position: absolute;
                top: -20px;
                left: 0;
                transition: all 0.8s ease;

            }

            @media (min-width: 1025px) {
                &:hover {
                    .team__nav__back {
                        transform: rotateY(0);
                    }

                    .team__nav__front {
                        transform: rotateY(-180deg);
                    }

                    &::before {
                        transform: rotateY(-180deg);
                    }

                    .team__nav__line {
                        transform: translateX(-50%) rotateY(-180deg);
                    }
                }
            }
            @media (max-width: 1024px) {
                &.active {
                    .team__nav__back {
                        transform: rotateY(0);
                    }

                    .team__nav__front {
                        transform: rotateY(-180deg);
                    }

                    &::before {
                        transform: rotateY(-180deg);
                    }

                    .team__nav__line {
                        transform: translateX(-50%) rotateY(-180deg);
                    }
                }
            }

            @include ex-medium {
                margin: 0 auto 70px;
            }

            ul {
                color: #ffffff;

                font-size: 12px;
                padding: 30px;
                line-height: 16px;

                li {
                    margin-bottom: 5px;
                }
            }

            @include main-screen {
                width: 305px;
            }
        }


        &__img {
            width: calWidth(225px);
            height: calHeight(225px);
            overflow: hidden;
            margin: 0 auto 45px;

            z-index: 3;
            @include main-screen {
                width: 225px;
                height: 225px;
            }
            @media (max-width: 1080px) {
                width: 150px;
                height: 150px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__content {


            h3 {
                font-weight: 600;
                @include fontSize(20);
                line-height: 23px;
                text-align: center;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #ffffff;
                margin-bottom: 10px;
            }

            p {
                font-weight: 500;
                @include fontSize(16);
                line-height: 19px;
                text-align: center;
                letter-spacing: -0.02em;
                color: #ffffff;
                max-width: 200px;
                margin: 0 auto;
            }
        }

        &__line {
            width: 167px;
            height: 13px;
            background: url('../../assets/imgs/icon/icon_line.png') no-repeat center;
            background-size: contain;
            position: absolute;
            left: 50%;
            bottom: -10px;
            transform: translateX(-50%);
            transition: all 0.8s ease;
        }

        &__front {
            padding-top: 25px;
        }

        &__back {
            padding-top: 15px;
            transform: rotateY(180deg);
            padding-bottom: 20px;
            overflow: auto;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: transparent;
            }

            &::-webkit-scrollbar {
                width: 6px;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 40px;
                background: linear-gradient(94.36deg, rgba(49, 148, 253, 0.7) 0.87%, rgba(73, 243, 255, 0.7) 97.84%);
            }

            a {
                display: table;
                margin: 0 auto;
            }

            img {
                &:hover {
                    opacity: .7;
                }
            }
        }

        &__side {
            height: 100%;
            transition: all 1s ease;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            // overflow: hidden;
            border: 1px solid #fff;
            background: linear-gradient(
                    180deg,
                    rgba(45, 129, 255, 0.17) 0%,
                    rgba(73, 243, 255, 0.085) 100%
            );

        }
    }

}

.investors {
    margin-bottom: 100px;
}

.list_02 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin-top: 60px;

    padding: 0 20px;
    @include ex-medium {
        justify-content: space-between;
    }

    li {
        width: 25%;
        margin-bottom: 25px;

        @include ex-medium {
            width: 48%;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            img {
                max-width: 100%;
            }
        }
    }
}

.footer {
    background-color: #000;
    padding: 45px;

    &__text {
        font-weight: 500;
        @include fontSize(16);
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #ffffff;
    }

    &__nav {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;

        li {
            a {
                display: block;
                margin: 0 15px;

                img {
                    max-width: 100%;
                }

                &:hover {
                    opacity: .8;
                }
            }
        }
    }
}

.modal {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -99999;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.fadeIn {
        opacity: 1;
        z-index: 99;
    }

    &__bg {
        background: rgba(#000, 0.7);
        height: 100vh;
        position: absolute;
        width: 100%;
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 17px;
        cursor: pointer;

        img {
            max-width: 100%;
        }
    }

    &__content {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: calWidth(790px);
        // max-height: calHeight(446px);
        width: 100%;
        @include main-screen {
            max-width: 790px;
        }
        @include ex-small {
            width: 96%;
            background-color: rgba(#000, 0.7);

        }
    }

    &__box {
        display: flex;
        @include ex-small {
            flex-direction: column;
        }
    }

    &__img {
        max-width: 100%;
        width: calWidth(386px);
        height: calHeight(446px);
        @include main-screen {
            width: 386px;
            height: 446px;
        }

        @include ex-small {
            width: 100%;
            height: 120px;

        }


        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            @include ex-small {
                object-fit: contain;
            }
        }
    }

    &__right {
        padding-top: 50px;
        background-color: #001244;
        width: calWidth(405px);
        padding-left: 20px;
        padding-right: 20px;
        @include ex-small {
            padding-bottom: 20px;
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 20px;
        }

        &__text_head {
            font-weight: 600;
            @include fontSize(16);
            line-height: 23px;
            text-align: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: $blue;
            margin-bottom: 10px;
        }

        &__list {
            list-style: none;
            margin-bottom: 20px;
            padding-left: 36px;

            li {
                font-weight: 500;
                @include fontSize(12);
                line-height: 19px;
                letter-spacing: -0.02em;
                color: $blue;
                position: relative;
                padding-left: 20px;

                &::before {
                    content: '';
                    width: 14px;
                    height: 14px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }
    }

    &__input {
        appearance: none;
        border: 1px solid #2c7ffc;
        color: $blue;
        font-weight: 500;
        @include fontSize(16);
        line-height: 19px;
        letter-spacing: -0.02em;
        padding: 0 10px;
        border-radius: 0;
        display: block;
        width: calWidth(252px);
        height: calHeight(36px);
        margin: 0 auto;
        background-color: transparent;
        margin-bottom: 10px;
    }

    &__submit {
        background: linear-gradient(93.14deg, #2c7ffc -5.6%, #49f3ff 103.31%);
        width: 118px;
        height: 27px;
        display: block;
        font-weight: 500;
        @include fontSize(16);
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #ffffff;
        border: 0;
        border-radius: 0;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 30px;
    }
}

.modal ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(44, 127, 252, 0.5);
    opacity: 1; /* Firefox */
}

.modal :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(44, 127, 252, 0.5);
}

.modal ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(44, 127, 252, 0.5);
}

.partner {
    .list_02 {
        li {
            margin-bottom: 50px;

            transition: 400ms ease-out;

            &:hover {
                transform: translate(0, -20px);
            }
        }
    }
}

.error {
    color: red;
    font-size: 12px;

    width: calc((252px * var(--zoom)));

    margin: 0 auto;

    min-height: 14px;
}

.split-columns-2 {
    display: grid;
    grid-template-columns: auto auto;

    @include ex-medium {
        grid-template-columns: auto;
    }
}